/* You can add global styles to this file, and also import other style files */

#exceptionSpan {
    white-space: pre;
    font-size: small;
    font-family: 'Consolas';
}

#codeBox {
    background-color: #ffffcc;
    overflow-x: scroll;
}

#propertiesBox {
    background-color: #d7ffee;
}

#methodText {
    color: rgb(0, 139, 139);
}

label {
    width: 500px !important;
}

mark {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(0, 139, 139);
}

html {
    overflow-y: scroll;
}

body {
    background-color: white !important;
}

.p-inputtext {
    width: 96vw !important;
}

.pi {
    visibility: hidden;
}